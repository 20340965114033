import React from "react";
import { Modal } from "flowbite-react";

export const AdseModal1 = ({ modalImg, isOpen, closeModal }) => {
  return (
    <React.Fragment>
      <Modal size="xl" show={isOpen}>
        <button
          onClick={closeModal}
          type="button"
          className="absolute -top-2 -right-2 rounded-full border-2 border-gray-500 bg-white p-1 text-gray-600"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <div className="p-3 pt-7">
          <img src={modalImg} alt="CourseImage1" className="h-full w-full" />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export const AdseModal2 = ({ modalImg, isOpen2, closeModal2 }) => {
  return (
    <React.Fragment>
      <Modal size="xl" show={isOpen2}>
        <button
          onClick={closeModal2}
          type="button"
          className="absolute -top-2 -right-2 rounded-full border-2 border-gray-500 bg-white p-1 text-gray-600"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <div className="p-3 pt-7">
          <img src={modalImg} alt="CourseImage1" className="h-full w-full" />
        </div>
      </Modal>
    </React.Fragment>
  );
};
export const AdseModal3 = ({ modalImg, isOpen3, closeModal3 }) => {
  return (
    <React.Fragment>
      <Modal size="xl" show={isOpen3}>
        <button
          onClick={closeModal3}
          type="button"
          className="absolute -top-2 -right-2 rounded-full border-2 border-gray-500 bg-white p-1 text-gray-600"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <div className="p-3 pt-7">
          <img src={modalImg} alt="CourseImage1" className="h-full w-full" />
        </div>
      </Modal>
    </React.Fragment>
  );
};
export const AdseModal4 = ({ modalImg, isOpen4, closeModal4 }) => {
  return (
    <React.Fragment>
      <Modal size="xl" show={isOpen4}>
        <button
          onClick={closeModal4}
          type="button"
          className="absolute -top-2 -right-2 rounded-full border-2 border-gray-500 bg-white p-1 text-gray-600"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <div className="p-3 pt-7">
          <img src={modalImg} alt="CourseImage1" className="h-full w-full" />
        </div>
      </Modal>
    </React.Fragment>
  );
};
export const AdseModal5 = ({ modalImg, isOpen5, closeModal5 }) => {
  return (
    <React.Fragment>
      <Modal size="xl" show={isOpen5}>
        <button
          onClick={closeModal5}
          type="button"
          className="absolute -top-2 -right-2 rounded-full border-2 border-gray-500 bg-white p-1 text-gray-600"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <div className="p-3 pt-7">
          <img src={modalImg} alt="CourseImage1" className="h-full w-full" />
        </div>
      </Modal>
    </React.Fragment>
  );
};
export const AdseModal6 = ({ modalImg, isOpen6, closeModal6 }) => {
  return (
    <React.Fragment>
      <Modal size="xl" show={isOpen6}>
        <button
          onClick={closeModal6}
          type="button"
          className="absolute -top-2 -right-2 rounded-full border-2 border-gray-500 bg-white p-1 text-gray-600"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <div className="p-3 pt-7">
          <img src={modalImg} alt="CourseImage1" className="h-full w-full" />
        </div>
      </Modal>
    </React.Fragment>
  );
};
export const AdseModal7 = ({ modalImg, isOpen7, closeModal7 }) => {
  return (
    <React.Fragment>
      <Modal size="xl" show={isOpen7}>
        <button
          onClick={closeModal7}
          type="button"
          className="absolute -top-2 -right-2 rounded-full border-2 border-gray-500 bg-white p-1 text-gray-600"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <div className="p-3 pt-7">
          <img src={modalImg} alt="CourseImage1" className="h-full w-full" />
        </div>
      </Modal>
    </React.Fragment>
  );
};
export const AdseModal8 = ({ modalImg, isOpen8, closeModal8 }) => {
  return (
    <React.Fragment>
      <Modal size="xl" show={isOpen8}>
        <button
          onClick={closeModal8}
          type="button"
          className="absolute -top-2 -right-2 rounded-full border-2 border-gray-500 bg-white p-1 text-gray-600"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <div className="p-3 pt-7">
          <img src={modalImg} alt="CourseImage1" className="h-full w-full" />
        </div>
      </Modal>
    </React.Fragment>
  );
};
export const AdseModal9 = ({ modalImg, isOpen9, closeModal9 }) => {
  return (
    <React.Fragment>
      <Modal size="xl" show={isOpen9}>
        <button
          onClick={closeModal9}
          type="button"
          className="absolute -top-2 -right-2 rounded-full border-2 border-gray-500 bg-white p-1 text-gray-600"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <div className="p-3 pt-7">
          <img src={modalImg} alt="CourseImage1" className="h-full w-full" />
        </div>
      </Modal>
    </React.Fragment>
  );
};
export const AdseModal10 = ({ modalImg, isOpen10, closeModal10 }) => {
  return (
    <React.Fragment>
      <Modal size="xl" show={isOpen10}>
        <button
          onClick={closeModal10}
          type="button"
          className="absolute -top-2 -right-2 rounded-full border-2 border-gray-500 bg-white p-1 text-gray-600"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <div className="p-3 pt-7">
          <img src={modalImg} alt="CourseImage1" className="h-full w-full" />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export const AdseModal11 = ({ modalImg, isOpen11, closeModal11 }) => {
  return (
    <React.Fragment>
      <Modal size="xl" show={isOpen11}>
        <button
          onClick={closeModal11}
          type="button"
          className="absolute -top-2 -right-2 rounded-full border-2 border-gray-500 bg-white p-1 text-gray-600"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <div className="p-3 pt-7">
          <img src={modalImg} alt="CourseImage1" className="h-full w-full" />
        </div>
      </Modal>
    </React.Fragment>
  );
};
